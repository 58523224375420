import React from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Headers from './Component/Header';
import Footer from './Component/Footer';
import Request from './Component/requestEstimate';
import s2 from './Component/s2.png';

function Contact() {
  return (
    <div>
      <div><Headers /> </div>
      <div className='text-center font-bold p-4 container'>
        <span style={{ fontSize: '35px' }}>Contact us</span> <br />
        <small className='justify'>GET IN TOUCH WITH THE FLOORING PROFESSIONALS AT OLIVER FLOORING</small>
      </div>
      <div className='row '>
        <div className='col-md-8'><Request /> </div>
        <div className='col-md-3 p-4'>
          <div className="max-w-md mx-auto mt-8 p-8 bg-white rounded-md shadow-md">
            <div className="mb-4">
              <h2 className="text-2xl font-bold ">Store Info</h2>
              <p className="text-gray-600 text-2xl ">Address</p>
              <p className="text-gray-800">
                482 State Farm Rd Boone NC 28607      </p>
              <a href="https://www.google.com/maps/place/482+State+Farm+Rd,+Boone,+NC+28607,+USA/@36.2063478,-81.6661099,17z/data=!3m1!4b1!4m6!3m5!1s0x8850fa46b39fc9b3:0x23af7b93dcfe7b12!8m2!3d36.2063435!4d-81.663535!16s%2Fg%2F11b8v66c4m?entry=ttu&g_ep=EgoyMDI0MDkwOC4wIKXMDSoASAFQAw%3D%3D" target="_blank" class="btn btn-warning  btn-lg active d-flex justify-content-center" role="button" style={{ borderRadius: '15px' }} rel="noreferrer">Get directions</a>

            </div>


            <div>
              <p className="text-gray-600">Phone</p>
              <p className="text-gray-800">(828) 865-1700</p>
            </div>

            <div className="mb-4">
              <a href="tel:+18288651700" class="btn btn-warning  btn-lg active d-flex justify-content-center" role="button" style={{ borderRadius: '15px' }} >Call us</a>

            </div>

            <div className="mb-4">
              <p className="text-gray-600">Hours</p>
              <p className="text-gray-800">
              Mon-Thu 1pm - 5pm Friday by<br />
              appointment only Sat-Sun Closed
              </p>
            </div>

            <div>
              <a href="/free-estimate" class="btn btn-warning  btn-lg active d-flex justify-content-center" role="button" style={{  borderRadius: '15px' }} >Request</a>

            </div>
          </div>
        </div>

      </div>
      <div class="container py-5">
        <a href='/financing'><img src={s2} alt="Los Angeles" class="d-block rounded" /></a>

      </div>
      <div class="container">
        <div class="map-responsive">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3219.3639507712114!2d-81.66610992363982!3d36.20634780094889!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8850fa46b39fc9b3%3A0x23af7b93dcfe7b12!2s482%20State%20Farm%20Rd%2C%20Boone%2C%20NC%2028607%2C%20USA!5e0!3m2!1sen!2sin!4v1726045080854!5m2!1sen!2sin" title="example" width="100%" height="450" frameborder="0"  allowfullscreen />
        </div>
      </div>
      <div><Footer /> </div>
    </div>
  )
}

export default Contact

import React from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Headers from './Component/Header';
import Footer from './Component/Footer';
import Request from './Component/requestEstimate';
import about from './Component/about.png';
import about1 from './Component/about1.png';
import brand1 from './Component/home/brand1.png';
import awards from './Component/awards.png';
import owner1 from './Component/owner1.png';
import owner2 from './Component/owner2.png';
import owner3 from './Component/owner3.png';

function About() {
  return (
    <div>
      <div><Headers /> </div>
      <div className='container p-4'>
        <div className='text-center font-bold p-4'>
          <span style={{ fontSize: '35px' }}>ABOUT OLIVER FLOORING</span> <br />
          <small className='justify'>GET TO KNOW YOUR MOUNTAIN CITY, TN AREA FLOORING EXPERTS</small>
        </div>
      </div>
	  <section class="bg-[#E38A02] sm:h-[80vh] sm:max-h-[500px] sm:mb-[50px]">
				<div className='container '>
					<div class="row flex  ">
					<div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 p-2 ">
						<img src={owner1} alt="Los Angeles" class=" sm:h-[90vh] sm:max-h-[550px] sm:relative sm:top-[-30px]" style={{ borderRadius: '6%' }} />
					</div>
					<div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 flex items-center justify-center p-2" style={{
						paddingInline: '5% 10%'
					}}>
							<div className='p-2 text-white text-left ' >
								<p class=" font-bold  text-2xl">Commitment to excellence in Mountain City, TN</p>
								<div className='row'>
									<div class="col-md-12">
										<div class="card-box-c foo">
											<div class="card-body-c align-self-left">
												<p class="content-c">
												<span className='font-semibold'>Flooring specialist</span>
												<br/>
												Here at Oliver Flooring we want to help you pick the right floor to insure you are 100% happy. We pride ourselves on being high quality specialist that will get the job done right the fist time.
												<br/><br/>
												<span className='font-semibold'>Oliver Flooring recommended</span><br/>
												<span className='font-semibold'>Customer satisfaction guaranteed</span><br/>
												We will loose sleep if our customers are not happy. Let us help you on your next project.<br/>
												</p>
												<p class="lead">
													<a href="/contact" class="btn btn-warning   btn-lg text-white active border-1 border-white" role="button" style={{ borderRadius: '18px' }}>Contact Us</a>
												</p>

											</div>
										</div>
									</div>

								</div>
							</div>
						</div>
					</div>
				</div>

			</section>
			<section class="bg-[#E38A02] sm:h-[80vh] sm:max-h-[500px] sm:mt-[50px]">
				<div className='container '>
					<div class="row flex  ">
					
					<div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 flex items-center justify-center p-2" style={{
						paddingInline: '5% 10%'
					}}>
							<div className='p-2 text-white text-left ' >
								<p class=" font-bold  text-2xl">Why should you choose us?</p>
								<div className='row'>
									<div class="col-md-12">
										<div class="card-box-c foo">
											<div class="card-body-c align-self-center">
											<p class="content-c p-3">
												<span className='font-semibold'>Oliver flooring does a great job</span>
												<br/>
												<span className='font-semibold'>Flooring installers you can trust</span><br/>
												We have installed thousands of square feet in hundreds of homes during our almost 30 years of business. Because we are installers we have a unique perspective on products. Waterproof vinyl plank flooring is the most popular flooring and we have something in every style and price range. Hardwood flooring is still a great option and we have many options. Call us and let us help you choose the right product that fits your needs and style. We’ll install your new floor right the first time so you can have peace of mind and enjoy your new home experience.
												<br/>
												</p>
											<p class="lead">
												<a href="/products" class="btn btn-warning  btn-lg active text-white" role="button" style={{ borderRadius: '18px' }}>Browse our catalogs</a>
											</p>

											</div>
										</div>
									</div>

								</div>
							</div>
						</div>
						<div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 p-2 ">
						<img src={owner2} alt="Los Angeles" class=" sm:h-[90vh] sm:max-h-[550px] sm:relative sm:top-[-30px]" style={{ borderRadius: '6%' }} />
					</div>
					</div>
					
				</div>

			</section>
	  <img
					src={awards}
					alt="Flooring, furniture and hardware needs"
					className="mx-auto py-3 mt-4"
				/>
      
      <div className='container '><img src={owner3} alt="Los Angeles" class="d-block w-full" /></div>
      

      <div><Request /></div>

      <section className='p-4 container border-t-2 py-2'>
        <div className='text-center font-bold'>
          <span style={{ fontSize: '25px' }}>Featured brands & technologies</span>
        </div>
        <section>
					<div >
							<img
								src={brand1}
								alt="brands"
								className="mx-auto "
							/>
						</div>
						
			</section>

      </section>
      <div><Footer /> </div>
    </div>
  )
}

export default About

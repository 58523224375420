import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
function RequestEstimate() {
  const [selectedDate, setSelectedDate] = useState(null);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    date: null,
    products: {
      Carpet: false,
      CarpetTile: false,
      Laminate: false,
      LuxuryVinyl: false,
      Tile: false,
      Vinyl: false,
      Waterproof: false,
      Cork: false,
      AreaRugs: false,
      HardWood: false,
      Solidsurface: false,
      MetalTile: false,
      Naturalstone: false,
      Glasstile: false,
    },
    comment: 'Regarding free quote',
    keepMeUpdated: false,
  });

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setFormData((prevFormData) => ({
      ...prevFormData,
      date: date,
    }));
  };

  const handleCheckboxChange = (checkboxName) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      products: {
        ...prevFormData.products,
        [checkboxName]: !prevFormData.products[checkboxName],
      },
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      //console.log(formData);
      const formresponse = await axios.post(`${process.env.REACT_APP_API_URL}/api/form`, formData);
      alert(formresponse.data.data1);
      window.location.reload();
      window.scrollTo(0, 0);

    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className='flex   w-[500px] item-center text-black justify-center'>
        
      <form className=" px-5 !bg-[#CECECEE0] bg-opacity-50" onSubmit={handleSubmit}>
        <div className='text-center font-bold pt-3'>
          <span className="text-[#E38A02] font-extrabold text-[27px]" style={{ fontSize: '27px' }} >LET'S GET STARTED
          </span> <br />
          <small> ON YOUR FREE QUOTE!</small>
        </div>
        <div className="rounded-md  flex flex-col  w-full item-center text-black justify-center">
          <div className="pt-3 pb-1">
            <input
              type="text"
              className="hover:!ring-4 w-[100%] shadow-sm shadow-gray-200 hover:!border-[1px] border !bg-white min-h-[40px] h-[40px] resize-y
                      rounded-[8px] outline-none  p-2 disabled:bg-gray-300 text-sm placeholder:text-sm
                      hover:!ring-blue-200  hover:!border-blue-500 focus:!ring-4 focus:!ring-blue-200 focus:!border-blue-500"
              id="firstname"
              placeholder="Full Name"
              value={formData.firstName}
              onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
              required
            />
          </div>
          <div className="py-1">
            <input
              type="number"
              className="hover:!ring-4 w-[100%] shadow-sm shadow-gray-200 hover:!border-[1px] border !bg-white min-h-[40px] h-[40px] resize-y
          rounded-[8px] outline-none  p-2 disabled:bg-gray-300 text-sm placeholder:text-sm
          hover:!ring-blue-200  hover:!border-blue-500 focus:!ring-4 focus:!ring-blue-200 focus:!border-blue-500"
              id="phoneNumber"
              placeholder="Phone Number"
              maxLength={10}
              value={formData.phoneNumber}
              onChange={(e) => setFormData({ ...formData, phoneNumber: e.target.value })}
              required
            />
          </div>
          <div className="py-1">
            <input
              type="text"
              className="hover:!ring-4 w-[100%] shadow-sm shadow-gray-200 hover:!border-[1px] border !bg-white min-h-[40px] h-[40px] resize-y
          rounded-[8px] outline-none  p-2 disabled:bg-gray-300 text-sm placeholder:text-sm
          hover:!ring-blue-200  hover:!border-blue-500 focus:!ring-4 focus:!ring-blue-200 focus:!border-blue-500"
              id="email"
              placeholder="Email"
              value={formData.email}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              required
            />
          </div>
          <div className="py-1">
            <input
              type="text"
              className="hover:!ring-4 w-[100%] shadow-sm shadow-gray-200 hover:!border-[1px] border !bg-white min-h-[40px] h-[40px] resize-y
          rounded-[8px] outline-none  p-2 disabled:bg-gray-300 text-sm placeholder:text-sm
          hover:!ring-blue-200  hover:!border-blue-500 focus:!ring-4 focus:!ring-blue-200 focus:!border-blue-500"
              id="address"
              placeholder="Zip Code"
              value={formData.address}
              onChange={(e) => setFormData({ ...formData, address: e.target.value })}
              required
            />
          </div>
          <div className="py-4 text-[10px] font-semibold">
            By clicking GET QUOTE, I provide my signature, consenting to communication from Oliver Flooring to the email and phone number provided, including by an auto-phone system, to hear more about sales and special events. Consent is not a condition of purchase. I can revoke this consent by calling us or by replying STOP to any text message. I understand calls are recorded for quality purposes. I agree to thePrivacy Policy, and Terms of Service including the Dispute Resolution Policy.
          </div>

        </div>
        <div className="py-4 flex items-center justify-center">
          <button
            type="submit"
            disabled={!(formData.phoneNumber.length === 10)}
            className="btn btn-warning  bg-blue-500 disabled:bg-slate-200 w-full  flex items-center justify-center text-white  rounded hover:bg-blue-600"
          >
            <span className="text-white font-bold text-[25px]"> GET QUOTE!</span>

          </button>
        </div>

      </form>
    </div>
  );
}

export default RequestEstimate;

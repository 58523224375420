import React from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Headers from './Component/Header';
import Footer from './Component/Footer';
import Request from './Component/requestEstimate';

function FreeEstimate() {
  return (
    <div>
      <div><Headers/> </div>
      <div><Request/> </div>
      <div><Footer/> </div>
    </div>
  )
}

export default FreeEstimate

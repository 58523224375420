/* eslint-disable max-len */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  status: false,
  message: '',
  title: '',
};

export const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    showError(state,action) {
      state.status = true;
      const { message } = action.payload;
      state.message = message;
    //   state.title = title;
    },
    clearError(state) {
      state.status = false;
      state.message = '';
    },
  },
});

export default errorSlice.reducer;

export const errorActions = errorSlice.actions;

export const showError = (message, title) => (dispatch) => dispatch(errorActions.showError({ message }));
export const removeError = () => (dispatch) => {
  dispatch(errorActions.clearError());
};

import { combineReducers, configureStore } from '@reduxjs/toolkit';
import userReducer from './userslice';
import errorReducer from './errorSlice';

export const rootRecucer = combineReducers({
  error: errorReducer,
});

export default configureStore({
  reducer: {
    user: userReducer,
    // covid: covidReducer,
    error: errorReducer,
  },
});

import React from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Headers from './Component/Header';
import Footer from './Component/Footer';
import { useNavigate } from 'react-router-dom';
import home2 from './Component/s3.png';
import s2 from './Component/s2.png';

function About() {
    const navigate = useNavigate();
	return (
		<div>
			<div><Headers /> </div>
			<div className='container'>
				<div className='text-center font-bold p-4'>
					<span style={{ fontSize: '35px' }}>Services</span> <br />
					<small className='justify'> SEE WHY WE’RE THE MOUNTAIN CITY, TN AREA'S MOST TRUSTED SERVICE PROVIDER</small>
				</div>
				<section >
					<div class="row p-3" >
						<div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
							<img src={home2} alt="Los Angeles" class="d-block rounded" />
						</div>
						<div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
							<ul className='jumbotron' >

								<li class=" text-slate-500  ">Hardwood refinishing <span class="text-sm text-[#E6712E] cursor-pointer" onClick={() => { navigate(`/hardwood-refinishing`); }} >Learn more</span></li>
								<li class=" text-slate-500  ">Area rug binding</li>
								<li class=" text-slate-500  ">Bathroom remodeling</li>
								<li class=" text-slate-500  ">Carpet binding</li>
								<li class=" text-slate-500  ">Carpet disposal</li>
								<li class=" text-slate-500  ">Carpet re-stretch</li>
								<li class=" text-slate-500  ">Commercial installations</li>
								<li class=" text-slate-500  ">Custom area rugs</li>
								<li class=" text-slate-500  ">Flooring repairs</li>
								<li class=" text-slate-500  ">Furniture removal</li>
								<li class=" text-slate-500  ">Grout cleaning</li>
								<li class=" text-slate-500  ">Residential installation</li>
								<li class=" text-slate-500  ">Job supervision & coordination</li>
								<li class=" text-slate-500  ">Mobile showroom</li>
								<li class=" text-slate-500  ">Outdoor installations</li>
								<li class=" text-slate-500  ">Setting material</li>
								<li class=" text-slate-500  ">Shop at home</li>
								<li class=" text-slate-500  ">Subfloor repair</li>
								<li class=" text-slate-500  ">Underlayment</li>


								<li class="">
									<a href="/free-estimate" class="btn btn-warning  btn-lg active" role="button" style={{ borderRadius: '15px' }}>Request an Estimate</a>
								</li>
							</ul>

						</div>
						<div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 py-5">
							<a href='/financing'><img src={s2} alt="Los Angeles" class="d-block rounded" /></a>
						</div>
					</div>
				</section >
			</div>
			<div><Footer /> </div>
		</div>
	)
}

export default About

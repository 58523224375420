import React from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Headers from './Component/Header';
import Footer from './Component/Footer';
import myImage from './Component/google-review.png';
function Reviews() {
  return (
    <div>
      <div><Headers /> </div>
      <div className='container'>
        <div className='text-center font-bold '>
          <span style={{ fontSize: '35px' }}>Reviews</span> <br />
          <small className='justify'> HOW DID WE DO? LEAVE US A REVIEW BELOW!</small>
          <div class="flex items-center justify-center ">
            <a href="https://www.google.com/maps/place/Oliver+Flooring/@36.4494945,-81.7979434,17z/data=!4m8!3m7!1s0x88505d9e2bf2603f:0x8d2df70de7a3a77c!8m2!3d36.4495552!4d-81.7974365!9m1!1b1!16s%2Fg%2F11h12rj5tp?hl=en&entry=ttu" target="_blank" rel="noreferrer">
              <img src={myImage} alt="Los Angeles" />
            </a>
          </div>
        </div>

        <section >
          <div>



          </div>
        </section >
      </div>
      <div className='sm:pt-[170px]'><Footer /> </div>
    </div>
  )
}

export default Reviews

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import userApi from '../api/user';

const initialState = {
    user: null,
    status: 'idle',
    isLoggedIn: false,
};



export const dlcdatabase = createAsyncThunk('user/dlcdatabase', async () => {
    const response = await userApi.dlcdatabase();
    return response.data.data;
});


export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(dlcdatabase.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(dlcdatabase.fulfilled, (state, action) => {
                state.status = 'success';
                state.isLoggedIn = true;
                state.user = { ...state.user, ...action.payload };
            })
            
    },
});

export default userSlice.reducer;

import axios from 'axios';

import { config } from '../Config';


const dlcdatabase = () => axios.get(`${process.env.REACT_APP_API_URL}/api/dlcdatabase`, config);




const usersApivalue = {
    dlcdatabase,



};

export default usersApivalue;


import React from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useNavigate } from 'react-router-dom';
import Headers from './Component/Header';
import Footer from './Component/Footer';
import Request from './Component/requestEstimate';
import s11 from './Component/s11.png';
import s12 from './Component/s12.png';

function Contact() {
    const navigate = useNavigate();
    return (
        <div>
            <div><Headers /> </div>
            <section className="hero-wrap hero-wrap-2" style={{ backgroundImage: `url(${s11})` }} data-stellar-background-ratio="0.5">
                <div className="overlay" style={{ background: 'rgba(0, 0, 0, 0.3)' }}>
                    <div className="container">
                        <div className="row no-gutters slider-text align-items-end justify-content-center">
                            <div className="col-md-9 ftco-animate mb-2 text-center p-5 text-white ">
                                <h2 className=" "><div className='text-white  capitalize'>Hardwood refinishing</div></h2>
                                <p class="mb-2 text-lg font-normal text-white lg:text-xl sm:px-16 xl:px-48 " >Wide variety of styles </p>

                                <a href='/free-estimate'> <div className='btn btn-warning  '>Request an Estimate</div> </a>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            <div className='row  justify-center items-center'>
                <div className='col-md-7  '><Request /> </div>
                <div className='col-md-5 '>
                    <div className=' p-4' >
                        <h2 class=" font-bold">FACTS TO KNOW ABOUT HARDWOOD REFINISHING</h2>
                        <div className='row'>
                            <div class="col-md-12">
                                <div class="card-box-c foo" style={{ borderRadius: '5px' }}>
                                    <div class="card-body-c align-self-center">
                                        <p class="content-c">
                                     One of the reasons hardwood lasts as long as it does, is that hardwood floor refinishing is an option. The hardwood refinishing process adds a great deal of lifespan and brings your floors back to a like-new look, even after decades of wear. If you're considering a refinish for your floors, this is a great time to learn more about it. If you need clarification on whether you need refinishing, we can help. We offer advice and consultations to determine your floors' needs before work starts. Here are some considerations for you as you work towards creating this project.                                
                                     </p></div>
                                     
                                     </div>
                            </div>

                        </div>
                        <h4 class=" font-bold">Refinish instead of replacing</h4>
                        <div className='row'>
                            <div class="col-md-12">
                                <div class="card-box-c foo" style={{ borderRadius: '5px' }}>
                                    <div class="card-body-c align-self-center">
                                        <p class="content-c">Why should you replace your floors when wood floor refinishing is an option? Also, refinishing your floors saves you money over time since you won't have to replace the materials. With professional installation and refinishing as necessary, wood floors can last more than 100 years.
                                        </p>

                                    </div>
                                </div>
                            </div>

                        </div>
                        {/* <p class="lead">
                            <a href="/free-estimate" class="btn btn-warning  btn-lg active" role="button" style={{ borderRadius: '15px' }}>Request an Estimate</a>
                        </p>       */}
                          </div>

                </div>

            </div>
            <div className='container'>
            <h3 class=" font-bold pt-3 text-center">How does hardwood floor refinishing start?</h3>
            <p class="content-c pt-2">The refinishing process starts with a consultation to determine your specific needs. For example, you may see the finish worn away, dark spots, or damage that points to a particular refinishing need, but you may need a professional assessment to find the best way to refinish.</p>
            <p class="content-c pt-2">After an assessment, sanding floors is the first step in the service, stripping away years of wear and damage. The result is a fresh layer of hardwood to add a new stain color and textured finish, with a protective topcoat to protect it all.</p>
            <p class="content-c pt-2">Once complete, you can further protect the surface with well-placed rugs or runners. In high-traffic areas, they can make a big difference and prolong the floor's lifespan. We can start your hardwood floor refinishing service when you visit our showroom or contact us.</p>

            <h3 class=" font-bold pt-3 text-center">Find your products and services right here</h3>
            <p class="content-c pt-2">Oliver Flooring offers wood floor refinishing and services for any size project you have in mind. We bring more than 30 years of experience to your remodel and provide great peace of mind with our professional installations. Our clients appreciate our dedication to ensuring the best results, and we're here to do the same for you.
            </p>
            <p class="content-c pt-2">            When ready to start your remodeling with hardwood refinishing,<span className='!text-[#E38A02] font-semibold px-2 cursor-pointer ' onClick={() => { navigate(`/contact`); }}>visit our showroom in BOONE ,NC </span> . We serve residents in and around Mountain City, TN, Boone, NC, Blowing Rock, NC, Banner Elk, NC, and Trade, TN, and we look forward to earning your business. So, stop by any time to find out more about hardwood floor refinishing and how we can get to work for you.
            </p>
            <p class="lead flex items-center justify-center">
                            <a href="/free-estimate" class="btn btn-warning  btn-lg active" role="button" style={{ borderRadius: '15px' }}>Request an Estimate</a>
                        </p>      

            </div>


            <div><Footer /> </div>
        </div>
    )
}

export default Contact
